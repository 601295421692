import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 3×6\\@75% 1RM`}</p>
    <p>{`Bent Leg Bridge 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`20-18-16-14-12-10-8-6-4-2 each:`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`50ft Shuttle Run`}</p>
    <p>{`Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`30 Minute Time Cap. Record Time or Total Reps at :30.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: if you are registered for the CrossFit Open you have
until 8:00pm tonight to submit your score.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      